import React, { useEffect, useMemo, useRef, useState } from "react";
import "./waiting-room.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  getWaitingList, getAppointments
} from "../../store/slices/appointmentSlice";
import ReactToPrint from "react-to-print";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock, faEye, faPrint, faCircleXmark, faTrash } from "@fortawesome/free-solid-svg-icons";
import CustomModal from "../../components/common/modal/modal";
import CreateAppointment from "../../components/appointment/create-appointment";
import Pagination from "react-js-pagination";
import { appointmentStatusChange, createVisit, deleteAppointment } from "../../service/api/appointment-api";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { formatDate } from "../../utils/common-funtions/common";
import PrescriptionPrint from "../../components/prescription/prescription-print/prescription-print";
import AppointmentWaitingFilter from "../../components/appointment/appointment-waiting-filter";
import { LocalStorageService } from "../../utils/local-storage";

const WaitingRoom = () => {
  const dispatch = useDispatch();
  const [selectedRow, setSelectedRow] = useState();
  const ref = useRef([]);
  const [length, setLength] = useState(50);
  let { isLoading, waitingListPagination, waitingList, pagination, allAppointments, noAppointmentFound
  } =
    useSelector((state) => state.appointment);
  const { currentPage, perPage, total } = pagination;
  let filterLocalData = LocalStorageService.get("waitingRoomFilter")
  const [show, setShow] = useState(false);
  const [showPrint, setShowPrint] = useState(false);
  const [patientId, setPatientId] = useState()
  const [visitId, setVisitId] = useState()
  const keyRef = useRef()
  const timeoutIdRef = useRef(null)
  const navigate = useNavigate()
  const [searchParams] = useSearchParams();
  const utcDate = new Date();
  let bdDate = utcDate.toLocaleDateString('en-US', { timeZone: 'Asia/Dhaka' });
  bdDate = bdDate.split('/')
  let date = `${bdDate[2]}-${bdDate[0].length == 1 ? ("0" + bdDate[0]) : bdDate[0]}-${bdDate[1]?.length == 1 ? ("0" + bdDate[1]) : bdDate[1]}`
  const [filterDate, setFilterDate] = useState({ startDate: filterLocalData?.startDate || searchParams.get('startDate') || date?.slice(0, 10), endDate: filterLocalData?.endDate || searchParams.get('endDate') || date?.slice(0, 10) })
  const [currentDate, setCurrentDate] = useState(true)
  const [status, setStatus] = useState(filterLocalData?.status || searchParams.get('status') || 'present')
  const [searchKey, setSearchKey] = useState("");
  const location = useLocation()
  const { user, userRoles, selectedRole } = useSelector((state) => state.auth);

  // useEffect(() => {
  //   dispatch(
  //     getAppointments({
  //       search: "",
  //       per_page: filterLocalData?.per_page || length,
  //       current_page: filterLocalData?.current_page || currentPage,
  //       startDate: filterLocalData?.startDate || filterDate?.startDate,
  //       endDate: filterLocalData?.endDate || filterDate?.endDate,
  //       status: filterLocalData?.status || status,
  //     })
  //   );
  // }, []);


  useMemo(() => {
    if (selectedRole?._id) {
      dispatch(
        getAppointments({
          search: "",
          per_page: filterLocalData?.per_page || length,
          current_page: filterLocalData?.current_page || currentPage,
          startDate: filterLocalData?.startDate || filterDate?.startDate,
          endDate: filterLocalData?.endDate || filterDate?.endDate,
          status: filterLocalData?.status || status,
          userRoleId: selectedRole?._id || '',
          doctorId: selectedRole?.user || ""
        })
      );
    }
  },
    [selectedRole?._id])

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      // console.log("event",e,ref.current)
      if (
        selectedRow &&
        ref.current[selectedRow] &&
        !ref.current[selectedRow].contains(e.target)
      ) {
        setSelectedRow(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [selectedRow]);

  const index = (page = 1, search = "", per_page = length, startDate = filterDate.startDate, endDate = filterDate?.endDate, statusVal = status) => {
    LocalStorageService.set('waitingRoomFilter', {
      per_page: per_page,
      current_page: page,
      startDate: startDate,
      endDate: endDate,
      status: statusVal,
    })

    dispatch(
      getAppointments({
        search: search,
        per_page: per_page,
        current_page: page,
        startDate: startDate,
        endDate: endDate,
        status: statusVal,
        userRoleId: selectedRole?._id || '',
        doctorId: selectedRole?.user || ""
      })
    );
  };

  const getSearchedAppointmentFun = (value) => {
    setSearchKey(value.trim());
    index(1, value.trim(), length, filterDate.startDate, filterDate?.endDate, status);
  };


  const handleKeyDown = (event) => {
    if ((event.key == "Enter" && keyRef?.current?.id == "waiting-room")) {
      event.preventDefault();
      getSearchedAppointmentFun(searchKey)
    }
  }

  useEffect(() => {
    if (searchKey)
      document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [searchKey]);

  // const appointmentSearchWithTimeOut = (event) => {
  //   clearTimeout(timeoutIdRef.current);
  //   timeoutIdRef.current = setTimeout(() => { getSearchedAppointmentFun(event) }, 700);
  // };
  const setStatusFun = async (status, info) => {
    await appointmentStatusChange(info?._id, {
      status: status?.toLowerCase(),
    })
      .then((res) => {
        if (res.status === 200) {
          index(1, searchKey, length, filterDate.startDate, filterDate?.endDate, status);
          setSelectedRow();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const createVisitAndNavigate = async (appointment) => {
    await createVisit(appointment?._id)
      .then((res) => {
        if (res.status === 200) {
          navigate(`/waiting-room/${res?.data?._id}`)
        }
      })
      .catch((error) => {
        console.log(error);
      });

  }

  const prescriptionPrintFun = async (appointment) => {

    await createVisit(appointment?._id)
      .then((res) => {
        if (res.status === 200) {
          setPatientId(appointment?.patient?._id)
          setVisitId(res?.data?._id)
          setShowPrint(true)
        }
      })
      .catch((error) => {
        console.log(error);
      });

  }

  const deleteAppointmentFun = async (appointment) => {
    await deleteAppointment(appointment?._id)
      .then((res) => {
        if (res.status === 200) {
          index(1, searchKey, length, filterDate.startDate, filterDate?.endDate, status);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const filterDateFun = (type, startDate, endDate) => {
    if (type == "current-date") {
      setCurrentDate(true)
      setFilterDate({ startDate: date?.slice(0, 10), endDate: date?.slice(0, 10) })
    } else if (type == 'between-date') {
      setCurrentDate(false)
      var date1 = new Date(startDate);
      var date2 = new Date(endDate);
      var diffDays = date2.getDate() - date1.getDate();
      if (diffDays < 0) {
        endDate = startDate
      }
    }
    setFilterDate({ startDate: startDate, endDate: endDate })
    index(currentPage, searchKey, length, startDate, endDate, status)
  }

  const filterStatusFun = (val) => {
    if (val == "clear") {
      setStatus('')
      index(currentPage, searchKey, length, filterDate?.startDate || date?.slice(0, 10), filterDate?.endDate || date?.slice(0, 10), '')
    }
    else if (val == 'reSet') {
      setStatus('present')
      index(currentPage, searchKey, length, filterDate?.startDate || date?.slice(0, 10), filterDate?.endDate || date?.slice(0, 10), 'present')
    }
    else {
      index(currentPage, searchKey, length, filterDate?.startDate || date?.slice(0, 10), filterDate?.endDate || date?.slice(0, 10), val)
    }
  }

  const setParamsFun = (status_val = status || "", startDate = filterDate.startDate || "", endDate = filterDate?.endDate || "") => {
    const url = `/waiting-room?startDate=${startDate}&endDate=${endDate}&status=${status_val}`
    navigate(url)
  }

  useMemo(() => {
    if (status || filterDate.startDate || filterDate?.endDate)
      setParamsFun()
  }, [status, filterDate.startDate, filterDate?.endDate])

  return (
    <div>
      {/* -------------- */}
      <section ref={keyRef} id="waiting-room" className="content pt-3 content-wrapper main-content appointment-page-container d-flex">
        <AppointmentWaitingFilter filterStatusFun={filterStatusFun} setStatus={setStatus} status={status} setFilterDate={setFilterDate} filterDate={filterDate} filterDateFun={filterDateFun} currentDate={currentDate} current width={"15%"} />
        <div className="container-fluid">
          {<div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-header">
                  <div className="appointment-add d-flex justify-content-between ">
                    <div className="d-flex">
                      <p
                        className="p-0 m-0 fw-bold text-secondary"
                        style={{ fontWeight: "500" }}
                      >
                        {" "}
                        Waiting Room
                      </p>
                      <FontAwesomeIcon
                        size="sm"
                        className="d-block ml-2 mt-1"
                        style={{ color: "#0fac81" }}
                        icon={faClock}
                      />
                    </div>

                    <div className="w-50 d-flex">
                      <input
                        className="appointment-search w-75"
                        placeholder="Search appointment"
                        type="text"
                        value={searchKey || ""}
                        onChange={(e) => {
                          setSearchKey(e.target.value)
                          // appointmentSearchWithTimeOut(e);
                        }}
                      ></input>
                      <button onClick={() => getSearchedAppointmentFun(searchKey)} className="btn my-0 py-1 mx-2 px-3" style={{ backgroundColor: "#0EAC81", color: "white", fontSize: "13px" }}>Search</button>
                      <button onClick={() => {
                        setSearchKey("")
                        index()
                      }} className="btn my-0 py-1 mx-2 px-3" style={{ backgroundColor: "#0EAC81", color: "white", fontSize: "13px" }}>Reset</button>
                    </div>
                  </div>
                </div>
                {/* /.card-header */}
                <div
                  className="card-body"
                  style={{ overflowX: "auto", height: "78vh" }}
                >
                  <table
                    id="example2"
                    className="table table-hover table-sm table-responsive{-sm|-md|-lg|-xl}"
                  >
                    <thead>
                      {/* table heading */}
                      <tr>
                        <th>Serial</th>
                        <th>Patient ID</th>
                        <th>Patient Name</th>
                        <th>Appointment Date</th>
                        <th>Appointment Type</th>
                        <th>Phone</th>
                        <th>Gender</th>
                        <th>Status</th>
                        <th>Visit</th>
                        {/* <th className="text-center">Print</th> */}
                        <th className="text-center">Delete</th>
                      </tr>
                    </thead>
                    {noAppointmentFound ? (
                      <small className="d-block ml-1">
                        No appointment found
                      </small>
                    ) : (
                      <tbody>
                        {allAppointments.appointments?.map((appointment, index) => {
                          return (
                            <tr
                              key={index}
                              className={`status-section ${appointment?._id === selectedRow
                                ? "active-row"
                                : "inactive-row"
                                }`}
                            // onClick={()=>console.log(appointment,index)}
                            >
                              {/* <td className="">{index + 1}</td> */}
                              <td className="">
                                {appointment?.serial}
                              </td>
                              <td className="">
                                {appointment?.patient?.patientId}
                              </td>
                              <td className="">
                                {appointment?.patientName}
                              </td>
                              <td className="">
                                {formatDate(appointment?.date)}
                              </td>
                              <td className="">
                                {appointment?.type}
                              </td>
                              <td className="">
                                {appointment?.patientPhone}
                              </td>
                              <td className="">
                                {appointment?.patientGender}
                              </td>
                              <td>
                                <p
                                  className={`mb-0 pb-0 d-flex ${selectedRow ? "disable-btn" : ""
                                    }`}
                                  onClick={() => {
                                    setSelectedRow(appointment?._id);
                                  }}
                                >
                                  <span className="pb-1">
                                    {appointment?.status}
                                  </span>
                                  <span
                                    title="click to change option-section"
                                    style={{ marginTop: "2px" }}
                                  ></span>
                                </p>
                              </td>
                              <td className="" onClick={() => { createVisitAndNavigate(appointment) }}>
                                <p className="visit-btn text-center">Visit<FontAwesomeIcon className="ml-1" size="sm"
                                  icon={faEye} /></p>
                              </td>
                              {/* <td className="" onClick={() => { prescriptionPrintFun(appointment) }}>
                                <p className=" text-center" ><FontAwesomeIcon style={{ color: "#13AC81" }} className="ml-1" size="md"
                                  icon={faPrint} /></p>
                              </td> */}
                              <td className='text-center'><span className='icon'> <FontAwesomeIcon onClick={() => {
                                deleteAppointmentFun(appointment)
                              }} icon={faTrash}></FontAwesomeIcon></span></td>
                            </tr>
                          );
                        })}
                      </tbody>
                    )}
                    {isLoading && <small className="ml-1">Loading...</small>}
                  </table>
                </div>
                {/* /.card-body */}
                <div className="card-footer">
                  <div className="float-left">
                    <div
                      className="dataTables_info"
                      id="example2_info"
                      role="status"
                      aria-live="polite"
                    >
                      <>
                        <select
                          defaultValue={50}
                          onChange={(e) => {
                            setLength(e.target.value);
                            index(currentPage, searchKey, e.target.value, filterDate.startDate, filterDate?.endDate, status);

                          }}
                        >
                          {/* <option value={5}>5 entries </option> */}
                          <option value={10}>10 entries </option>
                          <option value={20}>20 entries </option>
                          <option value={50}>50 entries </option>
                          <option value={100}>100 entries </option>
                        </select>{" "}
                        &nbsp; Showing {((filterLocalData?.current_page || currentPage) - 1) * (filterLocalData?.perPage || perPage) + 1} to{" "}
                        {total > (filterLocalData?.current_page || currentPage) * (filterLocalData?.perPage || perPage)
                          ? (filterLocalData?.current_page || currentPage) * (filterLocalData?.perPage || perPage)
                          : total}{" "}
                        of {total} entries
                      </>
                    </div>
                  </div>
                  <div className="float-right">
                    {noAppointmentFound && !isLoading ? (
                      ""
                    ) : (
                      <div className="d-flex justify-content-center">
                        <Pagination
                          activePage={parseInt(filterLocalData?.current_page || currentPage)}
                          itemsCountPerPage={parseInt(filterLocalData?.perPage || perPage)}
                          totalItemsCount={parseInt(total)}
                          // pageRangeDisplayed={5}
                          onChange={(currentPage) => {
                            index(currentPage, searchKey, length, filterDate.startDate, filterDate?.endDate, status);
                          }}
                          itemClass="page-item"
                          linkClass="page-link"
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
              {/* /.card */}
            </div>
            {/* /.col */}
          </div>}
          {/* /.row */}
          {/* { showPrint &&
            <div className="container">
              <div className="mt-0 pt-0 d-flex justify-content-between">
                <p onClick={() => { setShowPrint(false) }} style={{ cursor: "pointer" }} className="mt-0 pt-0"><FontAwesomeIcon className="mt-0 pt-0" icon={faCircleXmark} /></p>
                <p className="mt-0 pt-0 mr-2 d-inline-block px-2 rounded" style={{ background: "#13AC81", color: "white" }}>Print <FontAwesomeIcon className="mt-0 pt-0" icon={faPrint} /></p>
              </div>
              <PrescriptionPrint patientId={patientId} visitId={visitId}></PrescriptionPrint>
            </div>
          } */}
        </div>
        {/* /.container-fluid */}
      </section>
      {/*  modal */}
      <CustomModal
        customClass="appointment"
        size="xl"
        show={show}
        setShow={setShow}
        customSize={"size-72"}
      >
        <h1>
          <CreateAppointment
            setShow={setShow}
            refresh={index}
          ></CreateAppointment>
        </h1>
      </CustomModal>
      {/* print modal */}
      <CustomModal
        size="xl"
        show={showPrint}
        setShow={setShowPrint}
        isHeader={true}
      >
        <PrescriptionPrint setShowPrint={setShowPrint} patientId={patientId} visitId={visitId}></PrescriptionPrint>
      </CustomModal>
    </div>
  );
};

export default WaitingRoom;
