import React, { useEffect, useMemo, useState } from 'react'
import "./prescription-print.scss"
import { calculateDaysBetween, convertDobToAge, dateFormat, formatDate } from '../../../utils/common-funtions/common'
import rxImage from "../../../assets/images/RxIcon.png"

const PrescriptionBody = ({ info, patientDetails, drDetails }) => {
    const [signUrl, setSignUrl] = useState("")
    const [backGround, setBackGround] = useState("")
    const [reasonForVisit] = useState(info?.reasonForVisit?.trim().split('\n') || [])
    const [historyOfPresetIllness] = useState(info?.historyOfPresetIllness?.trim().split('\n') || [])
    const [extraNote] = useState(info?.extraNote?.trim().split('\n') || [])
    const [provisionalDx] = useState(info?.provisionalDx?.trim().split('\n') || [])
    const [differentialDx] = useState(info?.differentialDx?.trim().split('\n') || [])
    const [defaultFontSize, setDefaultFontSize] = useState("12pt")
    const [medicineFontSize, setMedicineFontSize] = useState("14pt")
    const [customPatientDetails, setCustomPatientDetails] = useState([])
    const [medicineDesign, setMedicineDesign] = useState(1)

    const customHeaderArrange = (data) => {
        const result = data.reduce((acc, item) => {
            if (item.visible) {
                acc[item.value] = item;
            }
            return acc;
        }, {});
        setCustomPatientDetails(result)
    }

    useMemo(() => {
        if (drDetails?.config?.prescriptionNamePosition == 3){
            customHeaderArrange(drDetails?.config?.customizeHeader)
        }
        if (drDetails?.config?.prescriptionDefaultFontSize)
            setDefaultFontSize(drDetails?.config?.prescriptionDefaultFontSize + "pt")
        if (drDetails?.config?.medicineFontSize)
            setMedicineFontSize(drDetails?.config?.medicineFontSize + "pt")
    }, [drDetails?.config])
    const viewSignature = async (url) => {
        let imageUrl = await getImageAsFile(process.env.REACT_APP_FILE_BASE_URL + '/' + encodeURIComponent(url))
        setSignUrl(imageUrl)
    }
    const viewBackGroundImage = async (url) => {
        let imageUrl = await getImageAsFile(process.env.REACT_APP_FILE_BASE_URL + '/' + encodeURIComponent(url))
        setBackGround(imageUrl)
    }



    async function getImageAsFile(imageUrl) {
        try {
            let token = JSON.parse(localStorage.getItem("user"))?.token;
            const response = await fetch(imageUrl, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            });
            const buffer = await response.arrayBuffer();
            const type = response.headers.get('Content-Type');
            const blob = new Blob([buffer], { type });
            return URL.createObjectURL(blob)
        } catch (error) {
            console.error('Error:', error);
            return null;
        }
    }

    useMemo(() => {
        if (drDetails?.config?.signatureWithImage) {
            viewSignature(drDetails?.details?.signature)
        }
        if (drDetails?.config?.prescriptionBackgroundImage) {
            viewBackGroundImage(drDetails?.details?.backgroundImage)
        }
        if (drDetails?.config?.medicineSectionDesign)
            setMedicineDesign(drDetails?.config?.medicineSectionDesign)
    }, [drDetails?.config?.signatureWithImage, drDetails?.config?.prescriptionBackgroundImage, drDetails?.config?.medicineSectionDesign])

    return (
        <div style={{ position: "relative", fontSize: defaultFontSize }} className={"prescription-body"}>
            {
                drDetails?.config?.prescriptionNamePosition == 1 &&
                <div className='d-flex justify-content-between patientInfo pb-1 pt-1 px-4'
                    style={{ fontSize: defaultFontSize, borderBottom: "1px solid black", borderTop: "1px solid black" }}>
                    <p>Name: <span> {patientDetails?.name}</span></p>
                    <p>Id: <span> {patientDetails?.patientId || " "}</span></p>
                    <p>Age: <span> {convertDobToAge(patientDetails?.dob?.slice(0, 10), "year(s)") || " "}</span></p>
                    <p>Gender: <span> {patientDetails?.gender || " "}</span></p>
                    <p>Date: <span> {formatDate(info?.appointmentDate)}</span></p>
                </div>
            }
            {drDetails?.config?.prescriptionNamePosition == "3" && Object.keys(customPatientDetails).length > 0 && <div className='d-flex'>
                {
                    Object.entries(customPatientDetails).map(([key, item]) => {
                        if (item?.value == "age")
                            return (
                                <div key={key} style={{ width: item.width + "%", position: "relative" }} className=' py-3'>
                                    <p style={{ position: "absolute", left: item.position_left + "%", top: "0%" }} className='my-0'>{convertDobToAge(patientDetails?.dob?.slice(0, 10), "year(s)")}</p>
                                </div>
                            )
                        else if (item?.value == "patientId")
                            return (
                                <div key={key} style={{ width: item.width + "%", position: "relative" }} className=' py-3'>
                                    <p style={{ position: "absolute", left: item.position_left + "%", top: "0%" }} className='my-0'>ID : {patientDetails?.patientId}</p>
                                </div>
                            )
                        else if (item?.value == "dob")
                            return (
                                <div key={key} style={{ width: item.width + "%", position: "relative" }} className=' py-3'>
                                    <p style={{ position: "absolute", left: item.position_left + "%", top: "0%" }} className='my-0'>{formatDate(patientDetails?.dob)}</p>
                                </div>
                            )
                        else if (item?.value == "appointmentDate")
                            return (
                                <div key={key} style={{ width: item.width + "%", position: "relative" }} className=' py-3'>
                                    <p style={{ position: "absolute", left: item.position_left + "%", top: "0%" }} className='my-0'>{formatDate(info?.appointmentDate)}</p>
                                </div>
                            )
                        else if (item?.value == "name")
                            return (
                                <div key={key} style={{ width: item.width + "%", position: "relative" }} className=' py-3'>
                                    <p style={{ position: "absolute", left: item.position_left + "%", top: "0%" }} className='my-0'>{patientDetails?.name}</p>
                                </div>
                            )
                        else if (item?.value == "gender")
                            return (
                                <div key={key} style={{ width: item.width + "%", position: "relative" }} className=' py-3'>
                                    <p style={{ position: "absolute", left: item.position_left + "%", top: "0%" }} className='my-0'>{patientDetails?.gender}</p>
                                </div>
                            )
                    })
                }
            </div>}

            <div className='d-flex ' style={{
                overflowY: "scroll",
                minHeight: drDetails?.config?.prescriptionBodyHeight ? drDetails?.config?.prescriptionBodyHeight + "px" : '1000px'
            }}>

                <div className={``} style={{
                    zIndex: "3333",
                    width: drDetails?.config?.prescriptionBodyLeftSectionWidth ? drDetails?.config?.prescriptionBodyLeftSectionWidth + "%" : "34%",
                    borderRight: drDetails?.config?.prescriptionDivider ? "1px solid black" : "none",
                    paddingRight: "20px",
                    paddingLeft: drDetails?.config?.prescriptionBodyLeftPadding ? (drDetails?.config?.prescriptionBodyLeftPadding + "px") : "20px",
                    paddingTop: "40px",
                    minHeight: (drDetails?.config?.prescriptionBodyHeight - 100) + "px"
                }}>
                    {
                        drDetails?.config?.prescriptionNamePosition == 2 &&
                        <div className='patientInfo mb-4 mr-2' style={{ fontSize: defaultFontSize }}>
                            <table id="example2"
                                className="table table-hover table-bordered table-sm table-responsive{-sm|-md|-lg|-xl}">
                                <tbody>
                                    <tr>
                                        <td className={"col-4"}>Name</td>
                                        <td className={"col-8"}><span> {patientDetails?.name}</span></td>
                                    </tr>
                                    <tr>
                                        <td className={"col-4"}>Id</td>
                                        <td className={"col-8"}><span> {patientDetails?.patientId || " "}</span></td>
                                    </tr>
                                    <tr>
                                        <td className={"col-4"}>Age</td>
                                        <td className={"col-8"}>
                                            <span> {convertDobToAge(patientDetails?.dob?.slice(0, 10), "year(s)") || " "}</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className={"col-4"}>Gender</td>
                                        <td className={"col-8"}><span> {patientDetails?.gender || " "}</span></td>
                                    </tr>
                                    <tr>
                                        <td className={"col-4"}>Date</td>
                                        <td className={"col-8"}>
                                            <span> {formatDate(info?.appointmentDate)}</span></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    }
                    <div>
                        {
                            <div className='mb-4' style={{ fontSize: defaultFontSize }}>
                                {
                                    (info?.reasonForVisit || info?.historyOfPresetIllness) &&
                                    <span className="title my-0 mb-1">CHIEF COMPLAINTS</span>
                                }
                                {
                                    info?.reasonForVisit && <div className="px-2 mb-2">
                                        <p className='pb-0 mb-0 text-dark'>Reason for visit</p>
                                        {
                                            reasonForVisit?.map((reason, index) => {
                                                return (<p className='my-0' key={index}><span style={{ fontSize: "16px" }}>&#8226;</span> {reason}</p>)
                                            })
                                        }
                                    </div>
                                }
                                {
                                    info?.historyOfPresetIllness &&
                                    <div className='mb-1 px-2'>
                                        <p className='pb-0 mb-0 text-dark'>History of present
                                            illness</p>
                                        {
                                            historyOfPresetIllness?.map((history, index) => {
                                                return (<p className='my-0' key={index}><span style={{ fontSize: "16px" }}>&#8226;</span> {history}</p>)
                                            })
                                        }
                                    </div>
                                }
                                {
                                    info?.extraNote &&
                                    <div className='mb-4 px-1'>
                                        <p className='pb-0 mb-0 text-dark title'>NOTE</p>
                                        {
                                            extraNote?.map((history, index) => {
                                                return (<p className='my-0' key={index}><span style={{ fontSize: "16px" }}>&#8226;</span> {history}</p>)
                                            })
                                        }
                                    </div>
                                }
                            </div>
                        }
                    </div>

                    <div className='care-plan'>
                        {
                            info?.carePlan?.details?.length > 0 && info?.carePlan?.details !== "<p><br></p>" && <div className='mb-4 inner-html' style={{ fontSize: defaultFontSize }}>
                                {
                                    (info?.carePlan?.details) &&
                                    <span className="title my-0 mb-1">CARE PLAN</span>
                                }
                                {
                                    info?.carePlan?.details && <div dangerouslySetInnerHTML={{ __html: info?.carePlan?.details }} className="px-2 mb-2">
                                    </div>
                                }
                            </div>
                        }
                    </div>

                    <div>
                        {
                            info?.vitals &&
                            <div className='mb-3' style={{ fontSize: defaultFontSize }}>
                                <div className='mb-4'>
                                    <span className='title pb-0 mb-2'>VITAL SIGNS</span>
                                    <div className='d-flex flex-wrap'>
                                        {info?.vitals?.height > 0 && <p className='mr-3 mb-2'><span
                                            className=''>Height: </span>{info?.vitals?.height + " ft" || " "}
                                        </p>}
                                        {info?.vitals?.weight > 0 && <p className='mr-3 mb-2'><span
                                            className=''>Weight: </span>{info?.vitals?.weight + " kg" || " "}
                                        </p>}
                                        {info?.vitals?.bmi > 0 && <p className='mb-2 mr-3'><span
                                            className=''>BMI: </span>{info?.vitals?.bmi || " "}</p>}
                                        {info?.vitals?.bp?.length > 1 && <p className='mb-2 mr-3'><span
                                            className=''>BP: </span>{info?.vitals?.bp || " "}</p>}
                                        {info?.vitals?.temperature && <p className='mb-2 mr-3'><span
                                            className=''>Temperature: </span>{info?.vitals?.temperature + "° F" || " "}
                                        </p>}
                                        {info?.vitals?.rr && <p className='mb-2 mr-3'><span
                                            className=''>Respiratory rate: </span>{info?.vitals?.rr + " / min" || " "}
                                        </p>}
                                        {info?.vitals?.spo2 && <p className='mb-2 mr-3'><span
                                            className=''>SPO2: </span>{info?.vitals?.spo2 + " %" || " "}
                                        </p>}
                                        {info?.vitals?.hr && <p className='mb-2 mr-3'><span
                                            className=''>Heart rate: </span>{info?.vitals?.hr + " / min" || " "}
                                        </p>}
                                        {info?.vitals?.note && <p className='mb-2'><span
                                            className=''>Note: </span>{info?.vitals?.note}
                                        </p>}

                                    </div>
                                </div>

                            </div>
                        }
                    </div>

                    {drDetails?.config?.isPhysicalExamShowInPrescription && <div className='mb-4' style={{ fontSize: defaultFontSize }}>
                        {info?.physicalExams?.length > 0 && <p className='title pb-0 mb-2'>O/E</p>}
                        <div>
                            {info?.physicalExams?.length > 0 && info?.physicalExams?.map((exam, index) => {
                                if (exam?.type?.toLowerCase() == 'abnormal') {
                                    let data = exam?.desc?.split('\n') || []
                                    return (<div style={{ padding: '4px', margin: '0px' }}>
                                        <p className='py-0 my-0'>&#8226;{exam?.displayName} : {exam?.type}</p>
                                        {data?.map((d, i) => {
                                            return (
                                                <p key={i} className='py-0 my-0 ml-3'> &#8226;{d}</p>
                                            )
                                        })}
                                    </div>)
                                }
                            })}
                        </div>
                    </div>}

                    <div>
                        {
                            <div className='mb-4' style={{ fontSize: defaultFontSize }}>
                                {
                                    info?.provisionalDx && <div className="pmb-2">
                                        <p className='title pb-0 mb-0'>PROVISIONAL DIAGNOSIS</p>
                                        {
                                            provisionalDx?.map((reason, index) => {
                                                return (<p className='my-0' key={index}><span style={{ fontSize: "16px" }}>&#8226;</span> {reason}</p>)
                                            })
                                        }
                                    </div>
                                }
                                {
                                    info?.differentialDx &&
                                    <div className='mb-4 mt-2'>
                                        <p className='title pb-0 mb-0'>DIFFERENTIAL DIAGNOSIS</p>
                                        {
                                            differentialDx?.map((history, index) => {
                                                return (<p className='my-0' key={index}><span style={{ fontSize: "16px" }}>&#8226;</span> {history}</p>)
                                            })
                                        }
                                    </div>
                                }
                            </div>
                        }
                    </div>

                    {
                        drDetails?.config?.visionInPrescription &&
                        <div className='' style={{ fontSize: defaultFontSize }}>
                            <span className='title pb-0 mb-2'>VISION</span>
                            <div className="spectacle p-1 vision ">
                                {(info?.eyeExamination?.aided?.left || info?.eyeExamination?.aided?.right) &&
                                    <div className='pb-0 mb-0'>
                                        <p className='py-0 my-0'>Visual Acuity: Aided</p>
                                        <p>Right Eye: {info?.eyeExamination?.aided?.left || ""} Left
                                            Eye: {info?.eyeExamination?.aided?.right || ""}</p>
                                    </div>}
                                {(info?.eyeExamination?.unaided?.left || info?.eyeExamination?.unaided?.right) &&
                                    <div className='pb-0 mb-0'>
                                        <p className='py-0 my-0'>Visual Acuity: Unaided</p>
                                        <p>Right Eye: {info?.eyeExamination?.unaided?.left} Left
                                            Eye: {info?.eyeExamination?.unaided?.right}</p>
                                    </div>}
                                {(info?.eyeExamination?.pinHole?.left || info?.eyeExamination?.pinHole?.right) &&
                                    <div className='pb-0 mb-0'>
                                        <p className='py-0 my-0'>Visual Acuity: Pin Hole</p>
                                        <p>Right Eye: {info?.eyeExamination?.pinHole?.left || ""} Left
                                            Eye: {info?.eyeExamination?.pinHole?.right || ""}</p>
                                    </div>}

                                {
                                    info?.eyeExamination?.diagnosis && Object.keys(info?.eyeExamination?.diagnosis).map((exam, index) => (
                                        <div key={index} className='d-flex  my-0'>
                                            <p className='py-0 my-0 pr-1'>{exam?.toUpperCase()}:</p>

                                            {Object.keys(info?.eyeExamination?.diagnosis[exam]).map((side, sideIndex) => (
                                                <p key={sideIndex} className='pr-2  my-0'>
                                                    {side == "left" ? "OS" : "OD"}: {info?.eyeExamination?.diagnosis[exam][side]}
                                                </p>
                                            ))}

                                        </div>
                                    ))
                                }

                                <p className='p-1 m-0 title text-center mt-1' style={{ border: "1px solid dimgrey" }}>Spectacle (Distance)</p>
                                <table
                                    id="example2"
                                    className="table table-hover table-sm table-responsive{-sm|-md|-lg|-xl}"
                                >
                                    <thead>
                                        <tr>
                                            <th>Eye</th>
                                            <th> Sphere</th>
                                            <th>Cylinder</th>
                                            <th>Axis</th>
                                            <th>Acuity</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td className="">Right</td>
                                            <td className="">{info?.eyeExamination ? info?.eyeExamination?.distance?.right?.sphere : ""}</td>
                                            <td className="">{info?.eyeExamination ? info?.eyeExamination?.distance?.right?.cylinder : ""}</td>
                                            <td className="">{info?.eyeExamination ? info?.eyeExamination?.distance?.right?.axis : ""}</td>
                                            <td className="">{info?.eyeExamination ? info?.eyeExamination?.distance?.right?.acuity : ""}</td>
                                        </tr>
                                        <tr>
                                            <td className="">Left</td>
                                            <td className="">{info?.eyeExamination ? info?.eyeExamination?.distance?.left?.sphere : ""}</td>
                                            <td className="">{info?.eyeExamination ? info?.eyeExamination?.distance?.left?.cylinder : ""}</td>
                                            <td className="">{info?.eyeExamination ? info?.eyeExamination?.distance?.left?.axis : ""}</td>
                                            <td className="">{info?.eyeExamination ? info?.eyeExamination?.distance?.left?.acuity : ""}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="spectacle vision m-1">
                                <p className='p-1 m-0 title text-center' style={{ border: "1px solid dimgrey" }}>Near</p>
                                <table
                                    id="example2"
                                    className="table table-hover table-sm table-responsive{-sm|-md|-lg|-xl}"
                                >
                                    <thead>
                                        <tr>
                                            <th>Eye</th>
                                            <th> Addition</th>
                                            <th>Acuity</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td className="">Right</td>
                                            <td className="">{info?.eyeExamination ? info?.eyeExamination?.near?.right?.addition : ""}</td>
                                            <td className="">{info?.eyeExamination ? info?.eyeExamination?.near?.right?.acuity : ""}</td>
                                        </tr>
                                        <tr>
                                            <td className="">Left</td>
                                            <td className="">{info?.eyeExamination ? info?.eyeExamination?.near?.left?.addition : ""}</td>
                                            <td className="">{info?.eyeExamination ? info?.eyeExamination?.near?.left?.acuity : ""}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div>
                                <p className='py-0 my-0'>IPD: {info?.eyeExamination ? info?.eyeExamination?.ipd : ""}</p>
                                <p>Glasses: {info?.eyeExamination ? info?.eyeExamination?.glasses : ""}</p>
                            </div>
                        </div>
                    }

                    <div>
                        {info?.labTests?.length > 0 &&
                            <p className={`title ${drDetails?.details?.visionInPrescription === "1" ? "pt-4" : "pt-5"}  mb-1`}> INVESTIGATION</p>}
                        {info?.labTests?.map((labTest, index) => {
                            return (
                                <p className='option my-0 pb-1'>
                                    {index + 1}. {labTest?.name}
                                </p>
                            )
                        })}
                    </div>
                </div>
                <div style={{
                    zIndex: "3333",
                    width: (100 - (+drDetails?.config?.prescriptionBodyLeftSectionWidth || 34)) + "%"
                }} className={` m-1 ${drDetails?.config?.prescriptionDivider ? "" : "pl-3"}`}>
                    {medicineDesign == 1 && <div>
                        {/* <p className='title pb-0 mb-0 pl-3 pt-2'
                            style={{ fontSize: "18pt" }}>{drDetails?.details?.config?.prescriptionRxTile ? "Rx" : ""}</p> */}
                        <div className='pt-4 ml-2 mb-3' style={{ height: "75px" }}>
                            {info?.medicines?.length > 0 && drDetails?.config?.prescriptionRxTile && <img className='' style={{ width: "45px" }} src={rxImage} alt="Rx" />}
                        </div>

                        <br></br>
                        {info?.medicines?.map((medicine, index) => {
                            let sigArray = medicine?.sig?.split("\n")
                            return (
                                <div key={index} className='option row no-gutters  p-0 m-0 ml-3' style={{ fontSize: defaultFontSize }}>
                                    <div className='d-flex p-0 m-0 col-5  pl-4'>
                                        {/* <FontAwesomeIcon size='sm' className='mt-3 p-0 m-0 mb-2' icon={faPills} /> */}
                                        <p className='mt-3  p-0 m-0 mb-2  pl-1'>{index + 1}. </p>
                                        <div>
                                            <small className='ml-2 p-0 m-0 mb-2 mt-1 ' style={{
                                                color: "black",
                                                fontStyle: "italic"
                                            }}>{medicine?.type}</small>
                                            <div className='d-flex'>
                                                <span className='ml-2 p-0 m-0 mb-2'
                                                    style={{ fontSize: medicineFontSize, fontWeight: "600" }}>{medicine?.name} </span>
                                                {/* <small className='ml-2 p-0 m-0 mb-2 mt-1' style={{
                                                    color: "black",
                                                    fontStyle: "italic"
                                                }}>{medicine?.power?.slice(0, 10)} </small> */}
                                            </div>
                                        </div>
                                    </div>
                                    {
                                        <div className='mt-3 col-5'>
                                            {
                                                sigArray.length > 0 && sigArray?.map((sig, index) => {
                                                    return (
                                                        <p key={index} className='p-0 m-0 '>{sig}</p>
                                                    )
                                                })
                                            }
                                        </div>
                                    }
                                    <p className='p-0 m-0 mt-3 col-2'>{medicine?.duration?.replace('/', ' ')}</p>
                                </div>
                            )
                        })}
                    </div>}

                    {medicineDesign == 2 && <div>
                        {/* <p className='title pb-0 mb-0 pl-3 pt-2'
                            style={{ fontSize: "18pt" }}>{drDetails?.details?.config?.prescriptionRxTile ? "Rx" : ""}</p> */}
                        <div className='pt-4 ml-2 mb-3' style={{ height: "75px" }}>
                            {info?.medicines?.length > 0 && drDetails?.config?.prescriptionRxTile && <img className='' style={{ width: "45px" }} src={rxImage} alt="Rx" />}
                        </div>
                        <br></br>
                        {info?.medicines?.map((medicine, index) => {
                            let sigArray = medicine?.sig?.split("\n")
                            return (
                                <div key={index} className='option row no-gutters ml-2' style={{ fontSize: defaultFontSize }}>
                                    <div className='d-flex p-0 m-0 col-12  pl-4'>
                                        {/* <FontAwesomeIcon size='sm' className='mt-3 p-0 m-0 mb-2' icon={faPills} /> */}
                                        <p className=' p-0 m-0 mb-1  pl-1'>{index + 1}. </p>
                                        <div>
                                            <div className='d-flex my-0 py-0'>
                                                <small className='ml-2 p-0 m-0  mt-1 ' style={{
                                                    color: "black",
                                                    fontStyle: "italic"
                                                }}>{medicine?.type}</small>
                                                <span className='ml-2 p-0 m-0 '
                                                    style={{ fontSize: medicineFontSize, fontWeight: "600" }}>{medicine?.name} </span>
                                                <small className='ml-2 p-0 m-0 mb-0 mt-1' style={{
                                                    color: "black",
                                                    fontStyle: "italic"
                                                }}>( {medicine?.generic?.length > 40 ? medicine?.generic?.slice(0, 40) + "..." : medicine?.generic} ) </small>
                                            </div>
                                            <div className='d-flex col-12 my-0 py-0'>
                                                <div className=' py-0 my-0'>
                                                    {
                                                        sigArray.length > 0 && sigArray?.map((sig, index) => {
                                                            return (
                                                                <p key={index} className='p-0 m-0 '>{sig}</p>
                                                            )
                                                        })
                                                    }
                                                </div>
                                                {medicine?.duration && <p className='pl-1'> - {medicine?.duration?.replace('/', ' ')}</p>}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>}
                    {/* <div className='px-2' style={{ height: "20%", fontSize: defaultFontSize }}> */}
                    <div className='px-2 ml-3 mt-3' style={{ fontSize: defaultFontSize }}>
                        {info?.advices?.length > 0 && <p className='title pb-0 mb-1'>ADVICE</p>}
                        {info?.advices?.map((advice, index) => {
                            let adviceArray = advice?.details?.trim().split('\n') || []
                            return (
                                adviceArray?.map((data, index) => {
                                    return (
                                        <p key={"advice" + index} className='option my-0 pb-1' style={{ fontSize: "12pt" }}>
                                            {data}
                                        </p>
                                    )
                                })
                            )

                        })}
                    </div>

                    {(info?.followUpDate || info?.followUpReason) && <div className='my-0 py-0 mt-3 ml-3'>
                        <p style={{ fontSize: defaultFontSize, textDecoration: "underline", fontWeight: "600" }} className='my-0 py-0 px-2' >Next Visit </p>
                        <p style={{ fontSize: defaultFontSize, fontWeight: "600" }}
                            className='px-2 my-0 py-0 pb-1 '>{info?.followUpDate ? "After " + calculateDaysBetween(info?.followUpDate?.slice(0, 10)) + " day(s), " : ""} {info?.followUpReason ? info?.followUpReason : ""} </p>
                        <p style={{ fontSize: defaultFontSize, fontWeight: "500" }}
                            className='px-2 my-0 py-0 pb-1 '>{info?.followUpDate ? "Date: " + formatDate(info?.followUpDate) : ""} </p>
                    </div>}

                    {
                        (drDetails?.config?.signatureWithImage || drDetails?.config?.signatureWithoutImage) &&
                        <div className='pt-3 mt-3' style={{
                            position: "absolute",
                            // top: drDetails?.config?.signatureWithoutImage ? "98%" : "95%",
                            left: "82%"
                        }}>
                            {drDetails?.config?.signatureWithImage &&
                                <img style={{ width: "200px", maxHeight: "200" }} src={signUrl} alt='check'></img>}
                            <p style={{ borderTop: "1px solid black" }} className='px-5'>Signature</p>
                        </div>
                    }
                    {/* {info?.followUpReason && <div className='my-0 py-0'>
                        <p style={{ fontSize: defaultFontSize }} className='my-0 py-0 px-2'>Next Visit Reason</p>
                        <p style={{ fontSize: defaultFontSize, fontWeight: "600" }}
                            className='px-2'>{info?.followUpReason}</p>
                    </div>} */}
                </div>
            </div>
            {drDetails?.config?.prescriptionBackgroundImage &&
                <div className='d-flex justify-content-center align-content-center' style={{
                    zIndex: "1",
                    position: "absolute",
                    height: "400px",
                    width: "600px",
                    top: "30%",
                    left: "21%"
                }}>
                    <div className='my-auto' style={{ maxWidth: "400" }}>
                        <img style={{ opacity: ".2" }} src={backGround}></img>
                    </div>
                </div>}
            {/* {
                (drDetails?.config?.signatureWithImage || drDetails?.config?.signatureWithoutImage) &&
                <div style={{
                    position: "absolute",
                    // top: drDetails?.config?.signatureWithoutImage ? "98%" : "95%",
                    left: "82%"
                }}>
                    {drDetails?.config?.signatureWithImage &&
                        <img style={{ width: "200px", maxHeight: "200" }} src={signUrl} alt='check'></img>}
                    <p style={{ borderTop: "1px solid black" }} className='px-5'>Signature</p>
                </div>
            } */}
        </div>
    )
}

export default PrescriptionBody
